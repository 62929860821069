<template>
    <div class="todo">
      <h2>This is my todo page.</h2>
    </div>
    <div class="todo-body">

    </div>
</template>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.todo {
  position: fixed;
  top: 15%;
  left: 35%;
  transform: translate(-50%, -50%);
}

.todo-body{
  position: fixed;
  top: 15%;
  left: 35%;
  transform: translate(-50%, -50%);
}
</style>